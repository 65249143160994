import React, { Component } from 'react'
import searchIcon from '@/icons/ui-search.svg'
import BetterIcon from '@/components/utils/BetterIcon'
import searchApi from '@/utils/search'
export default class Search extends Component {
	state = {
		value: ''
	}

	onChange = e => {
		const {
			target: { value }
		} = e

		this.setState({ value })
	}

	render() {
		const { value } = this.state
		const { onSubmit, bg = 'bg-white', text = 'text-grey' } = this.props

		return (
			<form
				role="search"
				className="text-xs-fluid w-full relative"
				onSubmit={e => {
					e.preventDefault()
					onSubmit(value)
				}}
			>
				<div className="flex w-full">
					<label htmlFor="site-search" className="block w-full">
						<input
							type="search"
							id="site-search"
							name="site_search"
							onChange={this.onChange}
							value={value}
							placeholder="e.g. help with benefits"
							aria-label="Search through site content"
							className={`block w-full h-full appearance-none focus:shadow-teal ${text} ${bg} p-0-5`}
						/>
					</label>
					{/* single input form... hit enter... don't really need to tab to the submit button */}
					<button
						type="submit"
						tabIndex="-1"
						className="bg-teal flex items-center justify-center p-0-25 w-45 h-45 trans trans-bg trans-slow appearance-none bg-teal hover:bg-orange p-0-5 relative"
					>
						<BetterIcon icon={searchIcon} className="w-15" />
						<span className="visuallyhidden">search</span>
					</button>
				</div>
			</form>
		)
	}
}
