import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import SEO from '@/components/SEO/SEO'
import { graphql } from 'gatsby'
import ContentGrid from '@/components/ContentGrid/ContentGrid'
import FeedHeader from '@/components/FeedHeader/FeedHeader'
import Pagination from '@/components/Pagination/Pagination'
import OrgThumb from '@/components/OrgThumb/OrgThumb'
import { prepItem, prepDirectoryFilterResult } from '@/utils'
import Dropdown from '@/components/Dropdown/Dropdown'
import Search from '@/components/Search/Search'
import filterQuery from '@/queries/filter'
import client from '@/utils/client'
import { setupPage } from '@/utils/common'

// OrgThumb.js
class Directory extends Component {
	constructor(props) {
		super(props)

		const {
			pageContext: { group, index, first, last, pageCount, pathPrefix }
		} = props

		setupPage(props)

		const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
		const nextUrl = (index + 1).toString()

		const entries = prepItem(group)

		const pages = Array.from({ length: pageCount }).map((_, i) => {
			const number = i + 1
			return {
				page: number,
				active: index === number,
				uri: i > 0 ? `${pathPrefix}/${number}` : pathPrefix
			}
		})

		this.state = {
			entries,
			pages,
			previousUrl,
			nextUrl,
			first,
			last,
			pathPrefix,
			paginate: true
		}
	}

	onFilterLocation = ({ key }) => {
		this.where = key
		this.onSearchQuery()
	}

	onFilterTags = ({ key }) => {
		this.id = key
		this.onSearchQuery()
	}

	onSubmit = value => {
		this.term = value
		this.onSearchQuery()
	}

	onSearchQuery = () => {
		const term =
			this.term && this.where
				? `${this.term} OR ${this.where}`
				: this.where && !this.term
				? this.where
				: !this.where && this.term
				? this.term
				: null

		client
			.request(filterQuery, {
				id: this.id ? this.id : null,
				term
			})
			.then(data => {
				const entries = prepDirectoryFilterResult(data)

				this.setState(() => ({
					entries,
					paginate: false
				}))
			})
	}

	render() {
		const {
			data: {
				craft: {
					entry,
				}
			},
			pageContext: {
				additionalContext: { locations, tags }
			},
			location
		} = this.props

		const {
			title,
			subtitle,
			body: { content: body },
			seo: {
				title: seoTitle,
				description: seoDescription,
				social: {
					facebook: {
						title: socialMetaTitle,
						description: socialMetaDescription,
						image: {
							url: seoImage,
						}
					}
				}
			}
		} = entry

		const {
			entries,
			pages,
			previousUrl,
			nextUrl,
			first,
			last,
			pathPrefix,
			paginate
		} = this.state

		return (
			<Fragment>
				<SEO
					title={seoTitle || title}
					description={seoDescription || subtitle}
					socialTitle={socialMetaTitle || title}
					socialDesc={socialMetaDescription || subtitle}
					url={location.href}
					image={seoImage}
				/>
				<FeedHeader title="Useful organisations">
					<div
						dangerouslySetInnerHTML={{ __html: body }}
						className="text-lg-fluid"
					/>
				</FeedHeader>
				<div className="border-b border-grey-brd mb-2 lg:mb-4">
					<div className="wrapper relative z-20 pb-1-25">
						<div className="flex flex-wrap lg:flex-start lg:items-center -ml-1 lg:ml-0">
							<div className="text-white text-xs-fluid mr-1 hidden lg:block">
								filter by
							</div>
							<div className="w-12/24 pl-1 lg:mr-0-25 lg:max-w-200">
								<Dropdown
									items={locations}
									placeholder="location"
									onChange={this.onFilterLocation}
								/>
							</div>
							<div className="w-12/24 pl-1 lg:max-w-200 mb-1 lg:mb-0">
								<Dropdown
									items={tags}
									placeholder="category"
									onChange={this.onFilterTags}
								/>
							</div>
							<div className="ml-auto flex items-center w-full lg:w-9/24 pl-1">
								<span className="mr-1 text-white text-xs-fluid whitespace-no-wrap hidden lg:block">
									search directory
								</span>
								<Search
									onSubmit={this.onSubmit}
									bg="bg-grey-brd"
									text="text-white"
								/>
							</div>
						</div>
					</div>
				</div>

				<ContentGrid
					content={entries}
					render={item => <OrgThumb {...item} />}
				/>
				<div className="wrapper my-4">
					{paginate && pages.length > 1 && (
						<Pagination
							prev={`${pathPrefix}/${previousUrl}`}
							next={`${pathPrefix}/${nextUrl}`}
							first={first}
							last={last}
							pages={pages}
						/>
					)}
				</div>
			</Fragment>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setPageType, setAudioBarVisibility } }) => ({
	setPageType,
	setAudioBarVisibility
})

export default connect(
	mapState,
	mapDispatch
)(Directory)

export const pageQuery = graphql`
	query DirectorPageQuery {
		craft {
			entry(id: 289) {
				... on Craft_LandingPagesUseful {
					title
					subtitle
					body {
						content
					}
					seo {
						title
						description
						social {
							twitter {
								title
								description
								image {
									url
								}
							}
							facebook {
								title
								description
								image {
									url
								}
							}
						}
					}
				}
			}
		}
	}
`
