import React, { Component } from 'react'
import Downshift from 'downshift'
import BetterIcon from '@/components/utils/BetterIcon'
import chevronDown from '@/icons/ui-chevron-down.svg'

export default class Dropdown extends Component {
	render() {
		const { onChange, items, placeholder } = this.props
		return (
			<Downshift
				onChange={value => {
					onChange(items.find(item => item.value === value))
				}}
			>
				{({
					isOpen,
					getToggleButtonProps,
					getItemProps,
					highlightedIndex,
					selectedItem
				}) => (
					<div className="w-full">
						<button
							type="button"
							className="block relative w-full bg-white px-0-5 py-0-5 text-black text-left text-xxs-fluid rounded-sm focus:shadow-teal"
							{...getToggleButtonProps()}
						>
							<span>{selectedItem || placeholder}</span>

							<span className="absolute pin-y flex items-center pin-r mr-0-5 text-teal block">
								<BetterIcon icon={chevronDown} className="w-15" />
							</span>
						</button>
						<div className="relative z-20">
							{isOpen && (
								<ul className="list-reset absolute w-full pin-l max-h-300 overflow-auto -mt-0-25 rounded-sm rounded-t-none">
									{items.map((item, index) => (
										<li
											className={`text-black text-xxs-fluid px-1 py-0-5 ${
												selectedItem === item.value ? 'font-bold' : ''
											}`}
											{...getItemProps({
												key: item.key,
												index,
												item: item.value,
												style: {
													backgroundColor:
														highlightedIndex === index ? 'lightgray' : 'white'
												}
											})}
											key={item.key}
										>
											{item.value}
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
				)}
			</Downshift>
		)
	}
}
