export default `query ($id: Int, $term: String) {
  tagsConnection(id: $id) {
    ... on TagConnection {
      edges {
        relatedEntries(section: [directory], search: $term) {
          entries {
            ... on Directory {
              title
              id
              address
              telephone
              website
              location
              tags {
                title
                id
              }
              directoryImage {
                ... on DirectoryVolume {
                  optimisedDirectory {
                    ... on OptimizedImagesData {
                      src
                      srcset
                      srcWebp
                      srcsetWebp
                      maxSrcsetWidth
                      placeholderWidth
                      placeholderHeight
                      colorPalette
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
