import React, { Component } from 'react'
import { Link } from 'gatsby'
import leftChevron from '@/icons/ui-left-chevron.svg'
import rightChevron from '@/icons/ui-right-chevron.svg'
import Icon from '@/components/utils/Icon'

const NavLink = ({ uri, children, test, className }) => {
	if (!test) {
		return (
			<Link className={className} to={uri}>
				{children}
			</Link>
		)
	}
	return <span className={`${className} opacity-50`}>{children}</span>
}

export default class Pagination extends Component {
	render() {
		const { pages, first, last, next, prev } = this.props
		return (
			<nav className="flex flex-wrap text-xxs-fluid justify-center">
				<NavLink
					test={first}
					uri={prev}
					className="flex items-center p-0-25 group"
				>
					<span className="mr-0-25 md:mr-0-5 group-hover:text-orange focus:text-orange  trans">
						Prev
					</span>
					<span className="text-teal flex items-center">
						<Icon icon={leftChevron} />
					</span>
				</NavLink>

				<ul className="list-reset flex items-center justify-center mx-2">
					{pages.map(({ uri, active, page }) => (
						<li key={uri} className="mx-0-25">
							<NavLink
								uri={uri}
								test={active}
								className={`${
									active ? '' : ''
								} p-0-25 md:px-0-5 border-b-4 border-transparent hover:border-teal focus:border-teal trans`}
							>
								{page}
							</NavLink>
						</li>
					))}
				</ul>

				<NavLink
					test={last}
					uri={next}
					className="flex items-center p-0-25 group outline-none hover:text-orange focus:text-orange trans"
				>
					<span className="mr-0-25 md:mr-0-5 text-teal flex items-center">
						<Icon icon={rightChevron} />
					</span>
					<span>Next</span>
				</NavLink>
			</nav>
		)
	}
}
